<script>
import ApiService from "@/services/api.service";
import { tippy } from "vue-tippy";
import { mapGetters } from "vuex";
import { UPDATE_HIBAH } from "@/services/store/amanah-pusaka.module";

export default {
  data() {
    return {
      form: {},
      submit: false,
    };
  },
  mounted() {
    this.form = this.$store.getters.hibah;
  },
  methods: {
    async submitForm() {
      this.form._method = "PATCH";
      await this.$store.dispatch(UPDATE_HIBAH, {
        hibahId: this.hibah.id,
        payload: this.form,
      });
      return;
    },
    async finish() {
      this.$swal.fire({
        icon: "success",
        html: "Proses amanah telah selesai.",
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Tutup",
      });
      this.$router.push({ name: "trust.list" });
    },
  },
  watch: {
    trust: {},
  },
  computed: {
    ...mapGetters(["preference", "userRole", "hibah"]),
    hibahId() {
      return this.$route.query.hibah_id ?? null;
    },
    amendmentId() {
      return this.$route.query.amendment_id;
    },
    routeName() {
      return this.$route.name;
    },
    message() {
      return this.$route.query.message;
    },
    trustDetails() {
      return this.trust;
    },
    hibahMoveable() {
      if (
        this.preference &&
        this.preference.hibah_allow_moveable_property == 1
      ) {
        if ([14].includes(this.trust.hibah_type_id)) {
          return true;
        } else return false;
      }
    },
    hibahImmoveable() {
      if (
        this.preference &&
        this.preference.hibah_allow_immoveable_property == 1
      ) {
        if ([14].includes(this.trust.hibah_type_id)) {
          return true;
        } else return false;
      }
    },
  },
  components: {},
};
</script>

<template>
  <div class="col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
    <div class="row">
      <div class="col-md-12" v-if="message == 'saved'">
        <div class="alert alert-success">{{ $t("trust.movprop-success") }}</div>
      </div>
      <div class="col-lg-12 col-12 mb-4 pb-2">
        <div
          class="card bg-light job-box rounded shadow border-0 overflow-hidden"
        >
          <div class="card-body">
            <h5 class="text-center mb-0 mt-4">
              Maklumat Harta Amanah
              <img
                content="Senarai harta-harta yang akan diagihkan"
                v-tippy
                height="20"
                class="mb-1"
                src="/images/icon/info-button.png"
              />
            </h5>

            <ul class="nav nav-tabs mt-4">
              <li class="nav-item" v-if="userRole != 'Agent'">
                <router-link
                  class="nav-link"
                  :class="
                    routeName == 'trust-inheritance.property.immoveable'
                      ? 'active'
                      : ''
                  "
                  :to="{
                    name: 'trust-inheritance.property.immoveable',
                    query: {
                      hibah_id: hibahId,
                    },
                  }"
                >
                  {{ $t("immov-prop") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :class="
                    routeName == 'trust-inheritance.property.moveable'
                      ? 'active'
                      : ''
                  "
                  :to="{
                    name: 'trust-inheritance.property.moveable',
                    query: {
                      hibah_id: hibahId,
                    },
                  }"
                >
                  {{ $t("mov-prop") }}
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :class="
                    routeName == 'trust-inheritance.property.transaction'
                      ? 'active'
                      : ''
                  "
                  :to="{
                    name: 'trust-inheritance.property.transaction',
                    query: {
                      hibah_id: hibahId,
                    },
                  }"
                >
                  Transaksi
                </router-link>
              </li>
            </ul>
            <router-view> </router-view>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12 text-right">
            <div class="form-group">
              <div class="d-flex justify-content-between"></div>
              <!-- <router-link
                :to="{
                  name: 'trust-inheritance.distribution',
                  query: {
                    hibah_id: hibahId
                  },
                }"
                class="btn btn-primary"
              >
                Simpan & Hantar
                <span
                  class="spinner-border spinner-border-sm"
                  v-if="submit"
                ></span>
              </router-link> -->
              <button @click="finish" class="btn btn-primary">
                Simpan & Hantar
                <span
                  class="spinner-border spinner-border-sm"
                  v-if="submit"
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
